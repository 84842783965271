
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'


const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;
let canJump = false;

let velocity = new THREE.Vector3()
const direction = new THREE.Vector3()
const vertex =new THREE.Vector3()

//Textures
// const textureLoader = new THREE.TextureLoader()
// const cubeTextureLoader = new THREE.CubeTextureLoader()


// const grassColorTexture = textureLoader.load('/textures/grass/color.jpg')
// const grassAmbientOcclusionTexture = textureLoader.load('/textures/grass/ambientOcclusion.jpg')
// const grassNormalTexture = textureLoader.load('/textures/grass/normal.jpg')
// const grassRoughnessTexture = textureLoader.load('/textures/grass/roughness.jpg')

// grassColorTexture.repeat.set(12, 12)
// grassAmbientOcclusionTexture.repeat.set(12, 12)
// grassNormalTexture.repeat.set(12, 12)
// grassRoughnessTexture.repeat.set(12, 12)


// grassColorTexture.wrapS = THREE.RepeatWrapping
// grassAmbientOcclusionTexture.wrapS = THREE.RepeatWrapping
// grassNormalTexture.wrapS = THREE.RepeatWrapping
// grassRoughnessTexture.wrapS = THREE.RepeatWrapping

// grassColorTexture.wrapT = THREE.RepeatWrapping
// grassAmbientOcclusionTexture.wrapT = THREE.RepeatWrapping
// grassNormalTexture.wrapT = THREE.RepeatWrapping
// grassRoughnessTexture.wrapT = THREE.RepeatWrapping
// //Cube


// let materialArray = [];
// let texture_ft = new THREE.TextureLoader().load('/cube/pz.jpg');
// let texture_bk = new THREE.TextureLoader().load('/cube/nz.jpg');
// let texture_up = new THREE.TextureLoader().load('/cube/py.jpg');
// let texture_dn = new THREE.TextureLoader().load('/cube/ny.jpg');
// let texture_rt = new THREE.TextureLoader().load('/cube/nx.jpg');
// let texture_lf = new THREE.TextureLoader().load('/cube/px.jpg');

// materialArray.push(new THREE.MeshBasicMaterial({map: texture_ft}));
// materialArray.push(new THREE.MeshBasicMaterial({map: texture_bk}));
// materialArray.push(new THREE.MeshBasicMaterial({map: texture_up}));
// materialArray.push(new THREE.MeshBasicMaterial({map: texture_dn}));
// materialArray.push(new THREE.MeshBasicMaterial({map: texture_rt}));
// materialArray.push(new THREE.MeshBasicMaterial({map: texture_lf}));

// for(let i=0; i<6; i++)
//     materialArray[i].side = THREE.BackSide;


//Models

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

let mixer = null

gltfLoader.load(
    // '/models/FlightHelmet/glTF/FlightHelmet.gltf',
    './models/scene1.glb',
    (gltf)=>
    {
        console.log(gltf);
        gltf.scene.scale.set(15,15,15)
        gltf.scene.position.y -= 1.2
        gltf.scene.rotation.x = -7.85
        scene.add(gltf.scene)

    }

)

// gltfLoader.load(
//     '/models/Fox/glTF-Binary/lampd1.glb',
//     (gltf2)=>
//     {   console.log(gltf2);

//         gltf2.scene.scale.set(50,50,50)
//         gltf2.scene.rotation.x = -7.85
//         scene.add(gltf2.scene)

//     }
// )




//Environnement
// const geometry = new THREE.BoxGeometry( 100, 100, 100 );
// const material = new THREE.MeshBasicMaterial( {color: 0xffffff , wireframe:true} );
// const cube = new THREE.Mesh( geometry, materialArray );
// cube.position.y = 10.1

// scene.add( cube );

// const geometryblock = new THREE.BoxGeometry( 15, 15, 15 );
// const materialblock = new THREE.MeshBasicMaterial( {color: 0x00ff00, wireframe:true} );
// const cubeblock = new THREE.Mesh( geometryblock, materialblock );
// scene.add( cubeblock );


/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(7, 7),
    // new THREE.MeshStandardMaterial({
    //     map: grassColorTexture,
    //     aoMap: grassAmbientOcclusionTexture,
    //     normalMap: grassNormalTexture,
    //     roughnessMap: grassRoughnessTexture
    // })
)
// floor.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(floor.geometry.attributes.uv.array, 2))
floor.receiveShadow = false
floor.rotation.x = - Math.PI * 0.5
floor.position.z -=2
scene.add(floor)

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0x404040, 5)
scene.add(ambientLight)


// const light = new THREE.PointLight( 0xffffff, 1, 100 );
// light.position.set( 0, 7, -2.5 );
// const helper3= new THREE.PointLightHelper(light, 2)
// scene.add(helper3)
// scene.add( light );

const spotLight = new THREE.SpotLight(0xffffff, 1, 20, Math.PI * 0.1, 0.25, 0.5)
spotLight.position.set(0, 10, -10)
// spotLight.target = floor
// const helper4 = new THREE.SpotLightHelper(spotLight)
// scene.add(helper4)
scene.add(spotLight)

// const directionalLight = new THREE.DirectionalLight(0xffffff, 5)
// directionalLight.castShadow = true
// directionalLight.shadow.mapSize.set(1024, 1024)
// directionalLight.shadow.camera.far = 15
// directionalLight.shadow.camera.left = - 30
// directionalLight.shadow.camera.top = 300
// directionalLight.shadow.camera.right = 30
// directionalLight.shadow.camera.bottom = - 30
// directionalLight.position.set(10, 10, 10)
// scene.add(directionalLight)
// const helper1 = new THREE.DirectionalLightHelper(directionalLight, 6)
// scene.add(helper1)

const hemisphericLight = new THREE.HemisphereLight(0xffffff, 0xffffff, )
hemisphericLight.position.y = 3
scene.add(hemisphericLight)
const helper = new THREE.HemisphereLightHelper(hemisphericLight, 1)
// scene.add(helper)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)

camera.position.set(0, 0.6,4)
scene.add(camera)

// // Controls
// const controls = new OrbitControls(camera, canvas)
// controls.target.set(0, 0.75, 0)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


const controls = new PointerLockControls(camera, document.body)

canvas.addEventListener('click', function(){
    controls.lock()
})
scene.add(controls.getObject())


const onKeyDown = function ( event ) {

    switch ( event.code ) {

        case 'ArrowUp':
        case 'KeyW':
            moveForward = true;
            console.log('oui')
            break;

        case 'ArrowLeft':
        case 'KeyA':
            moveLeft = true;
            break;

        case 'ArrowDown':
        case 'KeyS':
            moveBackward = true;
            break;

        case 'ArrowRight':
        case 'KeyD':
            moveRight = true;
            break;

        case 'Space':
            if ( canJump === true ) velocity.y += 350;
            canJump = false;
            break;

    }

};

const onKeyUp = function ( event ) {

    switch ( event.code ) {

        case 'ArrowUp':
        case 'KeyW':
            moveForward = false;
            break;

        case 'ArrowLeft':
        case 'KeyA':
            moveLeft = false;
            break;

        case 'ArrowDown':
        case 'KeyS':
            moveBackward = false;
            break;

        case 'ArrowRight':
        case 'KeyD':
            moveRight = false;
            break;

    }

};

// raycaster = new THREE.Raycaster( new THREE.Vector3(), new THREE.Vector3( 0, - 1, 0 ), 0, 10 );

document.addEventListener( 'keydown', onKeyDown );
document.addEventListener( 'keyup', onKeyUp );



/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime


    if ( controls.isLocked === true ) {

        // raycaster.ray.origin.copy( controls.getObject().position );
        // raycaster.ray.origin.y -= 10;

        // const intersections = raycaster.intersectObjects( objects, false );

        // const onObject = intersections.length > 0;

        const delta = 0.01
        console.log(delta);

        velocity.x -= velocity.x * 10.0 * delta;
        velocity.z -= velocity.z * 10.0 * delta;

        velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass

        direction.z = Number( moveForward ) - Number( moveBackward );
        direction.x = Number( moveRight ) - Number( moveLeft );
        direction.normalize(); // this ensures consistent movements in all directions

        if ( moveForward || moveBackward ) velocity.z -= direction.z * 400.0 * delta;
        if ( moveLeft || moveRight ) velocity.x -= direction.x * 400.0 * delta;

        // if ( onObject === true ) {

        //     velocity.y = Math.max( 0, velocity.y );
        //     canJump = true;

        // }

        controls.moveRight( - velocity.x * delta * 0.1);
        controls.moveForward( - velocity.z * delta * 0.1);

        controls.getObject().position.y += ( velocity.y * delta ); // new behavior

        if ( controls.getObject().position.y < 10 ) {

            velocity.y = 0;
            controls.getObject().position.y = 0.6;

            canJump = true;

        }

    }


    //Update mixer 
    if(mixer != null){

    
    mixer.update(deltaTime)}

    // Update controls
    // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()


// /**
//  * Base
//  */

// // Canvas
// const canvas2 = document.getElementById("renderCanvas");

// // Scene
// const scene2 = new THREE.Scene();

// const dracoLoader2 = new DRACOLoader();
// dracoLoader2.setDecoderPath("/draco/");

// const gltfLoader2 = new GLTFLoader();
// gltfLoader2.setDRACOLoader(dracoLoader2);

// let mixer2 = null;

// gltfLoader2.load("/models/Fox/glTF-Binary/scene1.glb", (gltf2) => {
//   gltf2.scene.scale.set(10, 10, 10);
//   gltf2.scene.position.y -= 1.2;
//   gltf2.scene.position.x -= 2.1;
//   scene2.add(gltf2.scene);
// });

// //Environnement

// /**
//  * Lights
//  */
// const ambientLight2 = new THREE.AmbientLight(0x404040, 1);
// scene2.add(ambientLight2);

// const spotLight2 = new THREE.SpotLight(
//   0xffffff,
//   1,
//   20,
//   Math.PI * 0.1,
//   0.25,
//   0.5
// );
// spotLight2.position.set(0, 10, -10);

// scene2.add(spotLight2);

// const hemisphericLight2 = new THREE.HemisphereLight(0xffffff, 0xffffff);
// hemisphericLight2.position.y = 3;
// scene2.add(hemisphericLight2);

// /**
//  * Sizes
//  */
// const sizes2 = {
//   width: window.innerWidth,
//   height: window.innerHeight,
// };

// window.addEventListener("resize", () => {
//   // Update sizes
//   sizes2.width = window.innerWidth;
//   sizes2.height = window.innerHeight;

//   // Update camera
//   camera2.aspect = sizes.width / sizes.height;
//   camera2.updateProjectionMatrix();

//   // Update renderer
//   renderer2.setSize(sizes.width, sizes.height);
//   renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 2));
// });

// /**
//  * Camera
//  */
// // Base camera
// const camera2 = new THREE.PerspectiveCamera(
//   75,
//   sizes2.width / sizes2.height,
//   0.1,
//   100
// );

// camera2.position.set(-3, 0.6, 4);
// scene2.add(camera2);

// // // Controls
// const controls2 = new OrbitControls(camera2, canvas2);
// controls2.target.set(0, 0.75, 0);
// controls2.enableDamping = true;

// /**
//  * Renderer
//  */
// const renderer2 = new THREE.WebGLRenderer({
//   canvas: canvas2,
// });

// renderer2.shadowMap.enabled = true;
// renderer2.shadowMap.type = THREE.PCFSoftShadowMap;
// renderer2.setSize(sizes2.width, sizes2.height);
// renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 2));

// canvas2.addEventListener("click", function () {
//   controls2.lock();
// });
// scene2.add(controls2);

// /**
//  * Animate
//  */
// const clock2 = new THREE.Clock();
// let previousTime2 = 0;

// const tick2 = () => {
//   const elapsedTime2 = clock2.getElapsedTime();
//   const deltaTime2 = elapsedTime2 - previousTime2;
//   previousTime2 = elapsedTime2;

//   //Update mixer
//   if (mixer2 != null) {
//     mixer2.update(deltaTime2);
//   }

//   // Update controls
//   // controls.update()

//   // Render
//   renderer2.render(scene2, camera2);

//   // Call tick again on the next frame
//   window.requestAnimationFrame(tick2);
// };

// tick2();
